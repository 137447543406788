<template>
  <div class="tw-w-full tw-px-2 md:tw-px-8">
    <back-nav to="ClientQuotation" />
    <div class="tw-grid tw-grid-cols-1  tw-mt-2 md:tw-grid-rows-1 tw-gap-2 tw-px-6 tw-py-6  md:tw-gap-3 md:tw-px-4 md:tw-py-4 md:tw-rounded-lg
          md:tw-border-1 tw-bg-gray-100">
        <h2 class="tw-text-xl  c-blue-text tw-font-bold">Client Details</h2>
      <div
        class="
          tw-grid tw-grid-cols-1
          md:tw-grid-cols-6
          tw-gap-2
          md:tw-gap-4
        "
      >
        <div>
          <p class="c-blue-text">Full Name</p>
          <p class="c-gray-text tw--mt-3">
            {{ scanObject(quoteSummaryData, 'client.clientName') }}
          </p>
        </div>
        <div>
          <p class="c-blue-text">Email Address</p>
          <p class="c-gray-text tw--mt-3">
            {{ scanObject(quoteSummaryData, 'client.clientEmail') }}
          </p>
        </div>
        <div>
          <p class="c-blue-text">Sum Assured (KES)</p>
          <p class="c-gray-text tw--mt-3">
            {{ scanObject(quoteSummaryData, 'sumAssured') | formatNum }}
          </p>
        </div>
        <div>
          <p class="c-blue-text">Date of Birth</p>
          <p class="c-gray-text tw--mt-3">
            {{ scanObject(quoteSummaryData, 'client.clientDateOfBirth') | formatToHuman }}
          </p>
        </div>
        <div>
          <p class="c-blue-text">Term in Years</p>
          <p class="c-gray-text tw--mt-3">{{ scanObject(quoteSummaryData, 'term') }}</p>
        </div>
        <div>
          <p class="c-blue-text">Frequency</p>
          <p class="c-gray-text tw--mt-3">
            {{ scanObject(quoteSummaryData, 'freqOfPayment') | frequencyTitles }}
          </p>
        </div>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2 md:tw-gap-3 tw-px-6 tw-py-6 md:tw-mt-8 md:tw-px-6 md:tw-py-6 md:tw-rounded tw-bg-gray-100
          md:tw-border-1 ">
      <div class=" tw-border-r-0
          md:tw-border-r-2">
        <label class="tw-text-xl c-blue-text tw-font-bold">Proposal Details</label>
        <div class="md:tw-mt-2">
        <div v-if="quoteSummaryData.prodCode === 2013103 || quoteSummaryData.prodCode === 2013104 || quoteSummaryData.prodCode === 2013102">
        <div class="tw-grid tw-grid-cols-3 tw-gap-2">
        <div><h5 class="c-blue-text tw-mt-2 md:tw-mt-0 tw-mb-3 tw-font-bold">
          Premium Breakdown (KES)
        </h5></div>
        <div><h5 class="c-blue-text tw-mt-2 md:tw-mt-0 tw-mb-3 tw-font-bold">
          Premiums (KES)
        </h5></div>
        <div><h5 class="c-blue-text tw-mt-2 md:tw-mt-0 tw-mb-3 tw-font-bold">
          Sum Assured (KES)
        </h5></div>
        </div>
        <div class="tw-grid tw-grid-cols-3 tw-gap-5" v-for="(benefit, i) in scanObject(quoteSummaryData, 'benefits', [])" :key="i">
        <div class="tw-mt-2 tw-ml-2 md:tw-mt-4 tw-text-lg">{{ i === 0 ? 'Main Benefit' : benefit.quoteBenefitName }}</div>
        <div class="tw-mt-2 md:tw-mt-4 tw-text-lg"><strong> {{ Math.round(benefit.quoteBenefitPrem) | formatNum}} </strong></div>
        <div class="tw-mt-2 md:tw-mt-4 tw-text-lg"><strong>{{ benefit.quoteBenefitName === 'Waiver Of Premium' || benefit.quoteBenefitName === 'Waiver Of Premium (Inbuilt)' ? 0 : Math.round(benefit.quoteBenefitSA) | formatNum }}</strong></div>
        </div>
      </div>
      <div v-else>
        <h5 class="c-blue-text tw-mt-2 md:tw-mt-0 tw-mb-3 tw-font-bold">
          Premium Breakdown (KES)
        </h5>
        <ul v-for="(benefit, i) in scanObject(quoteSummaryData, 'benefits', [])" :key="i" class="tw-w-full">
          <v-row v-if="benefit.quoteBenefitPrem !== 0">
            <v-col cols="12" md="6"
              ><li class="tw-mt-2 md:tw-mt-4">
                {{ i === 0 ? 'Main Benefit' : benefit.quoteBenefitName }}
              </li>
              </v-col
            >
            <v-col cols="6" md="3"
              >
              <li class="tw-mt-2 md:tw-mt-4">
                :
                <strong> {{ Math.round(benefit.quoteBenefitPrem) | formatNum }}</strong>
              </li></v-col
            >
          </v-row>
        </ul>
      </div>

        <div class="tw-mt-3 md:tw-mt-8 tw-px-2 md:tw-px-6">
          <div v-if="scanObject(quoteSummaryData, 'maturities', []).length > 2">
            <h5 class="c-blue-text tw-font-bold tw-pb-0">
              Payout Schedule (KES)
            </h5>
            <ul class="tw-w-full c-gray-text">
              <div>
                <v-row>
                  <v-col cols="12" md="6"
                    ><li class="tw-mt-2 md:tw-mt-4">
                      1st Partial maturity 15% of sum Assured
                    </li></v-col
                  >
                  <v-col cols="12" md="6"
                    ><li class="tw-mt-2 md:tw-mt-4">
                      :
                      <strong>
                        {{ Math.round(scanObject(quoteSummaryData, 'maturities.0.quoteMatAmount', 0)) | formatNum }}
                      </strong>
                    </li></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="12" md="6"
                    ><li class="tw-mt-2 md:tw-mt-4">
                      2st Partial maturity 25% of sum Assured
                    </li></v-col
                  >
                  <v-col cols="12" md="6"
                    ><li class="tw-mt-2 md:tw-mt-4">
                      :
                      <strong>
                        {{ Math.round(scanObject(quoteSummaryData, 'maturities.1.quoteMatAmount', 0)) | formatNum }}
                      </strong>
                    </li></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="12" md="6"
                    ><li class="tw-mt-2 md:tw-mt-4">
                      At policy maturity 60% of sum Assured
                    </li></v-col
                  >
                  <v-col cols="12" md="6"
                    ><li class="tw-mt-2 md:tw-mt-4">
                      :
                      <strong>
                        {{ Math.round(scanObject(quoteSummaryData, 'maturities.2.quoteMatAmount', 0)) | formatNum }}
                      </strong>
                    </li></v-col
                  >
                </v-row>
              </div>
            </ul>
          </div>

          <br />
          <v-icon color="warning" size="17">mdi-information-outline</v-icon>
          <small class="c-yellow-text tw-italic"
            >Disclaimer: Amount may change based on the portfolio performance</small
          >
        </div>
      </div>
      </div>
      <div>
        <label class="tw-text-xl c-blue-text tw-font-bold tw-mt-2">{{ getSummaryTitle }}</label>
        <div class="tw-mt-3 md:tw-mt-8 tw-px-2 md:tw-px-6">
          <div>
            <ul class="tw-w-full c-gray-text">
              <v-row>
                <v-col cols="12" md="6"><li class="tw-mt-2 md:tw-mt-4">Sum Assured</li></v-col>
                <v-col cols="12" md="6"
                  ><li class="tw-mt-2 md:tw-mt-4">
                    :
                    <strong>
                      {{ Math.round(scanObject(quoteSummaryData, 'sumAssured', 0)) | formatNum }}
                    </strong>
                  </li></v-col
                >
              </v-row>
              <v-row v-if="scanObject(quoteSummaryData, 'terminalBonus')">
                <v-col cols="12" md="6"><li class="tw-mt-2 md:tw-mt-4">Terminal Bonus</li></v-col>
                <v-col cols="12" md="6"
                  ><li class="tw-mt-2 md:tw-mt-4">
                    :
                    <strong>
                      {{ Math.round(quoteSummaryData.terminalBonus) | formatNum }}
                    </strong>
                  </li></v-col
                >
              </v-row>
              <v-row v-if="scanObject(quoteSummaryData, 'reversionaryBonus')">
                <v-col cols="12" md="6"
                  ><li class="tw-mt-2 md:tw-mt-4">
                    Reversionary Bonus Value
                  </li></v-col
                >
                <v-col cols="12" md="6"
                  ><li class="tw-mt-2 md:tw-mt-4">
                    :
                    <strong>
                      {{ Math.round(scanObject(quoteSummaryData, 'reversionaryBonus', 0)) | formatNum }}
                    </strong>
                  </li></v-col
                >
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row>
                <v-col cols="12" md="6"
                  ><li class="tw-mt-2 md:tw-mt-2">
                    Total {{ getSummaryTitle }}
                  </li></v-col
                >
                <v-col cols="12" md="6"
                  ><li class="tw-mt-2 md:tw-mt-4">
                    :
                    <strong>
                      {{ Math.round(scanObject(quoteSummaryData, 'totalBenefit', 0)) | formatNum }}
                    </strong>
                  </li></v-col
                >
              </v-row>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-my-3
        md:tw-my-8
        tw-text-center
      "
    >
      <div class="tw-w-full md:tw-w-2/3 tw-mt-3 md:tw-mt-8">
        <div
          v-show="scanObject(quoteSummaryData, 'sumAssured') > 5000000 || getAge >= 50"
          class="tw-bg-yellow-100 tw-rounded-lg tw-p-2"
        >
          <span>
            <v-icon color="warning">mdi-information-outline</v-icon>
            <small class="c-yellow-text tw-px-2">
              Based on the calculations, you will be required to go for a medical checkup.</small
            >
          </span>
        </div>
        <div
          class="
            tw-flex tw-flex-wrap tw-items-center tw-my-3
            md:tw-my-8
            tw-text-center
          "
        >
          <div
            class="
              tw-w-full
              md:tw-w-1/2
              tw-mt-4
              md:tw-mt-0
              tw-border-r-0
              md:tw-border-r-2
            "
          >
            <h3 class="c-blue-text tw-font-bold tw-text-sm">
              Premium Payable
              {{ scanObject(quoteSummaryData, 'freqOfPayment') | frequencyTitles }}
            </h3>
            <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
              KES {{ Math.round(scanObject(quoteSummaryData, 'premium', 0)) | formatNum }}
            </h2>
          </div>
          <div class="tw-w-full md:tw-w-1/2 tw-mt-4 md:tw-mt-0">
            <h3 class="c-blue-text tw-font-bold tw-text-sm">
              Total {{ getSummaryTitle }}
            </h3>
            <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
              KES {{ Math.round(scanObject(quoteSummaryData, 'totalBenefit', 0)) | formatNum }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-wrap tw-my-3 md:tw-my-5 tw-justify-center">
      <div class="tw-space-x-2">
        <v-btn outlined color="primary" @click="activateMailModal">Email Quote</v-btn>
        <v-btn outlined color="primary" @click="downloadReport" :loading="downloadQuote"
          >Download</v-btn
        >
      </div>
    </div>
    <div class="tw-flex tw-justify-center tw-mt-3 md:tw-mt-5">
      <v-btn
        width="300"
        :loading="loading"
        :to="{ name: 'AgentGuide' }"
        @click="loading = true"
        color="warning"
        >Proceed</v-btn
      >
    </div>
    <EmailQuote
      :email="scanObject(quoteSummaryData, 'client.clientEmail')"
      :docType="reportType"
      :editable="true"
      :mailDialog="mailDialog"
      :quoteId="quoteId"
      @changeDialog="updateDialog"
    />
  </div>
</template>

<script>
import axios from 'axios';
import download from 'downloadjs';
import BackNav from '../BackNav';
import EmailQuote from './EmailQuotation';
import { age, formatToHuman } from '@/utils/time';
import helpers from '@/utils/helpers';

export default {
  name: 'QuoteSummary',
  components: { BackNav, EmailQuote },
  props: ['quoteSummaryData'],
  data() {
    return {
      lien: false,
      mailDialog: false,
      quoteId: this.scanObject(this.quoteSummaryData, 'quoteCode'),
      downloadQuote: false,
      loading: false,
      reportType: 'quote',
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'quotesummary_page_visited_by_agent', {
        event_category: 'quote summary component created',
        event_label: 'quote summary component created',
      });
    }
  },
  methods: {
    async downloadReport() {
      this.downloadQuote = true;
      const response = await axios.post(
        `/lmsagent/quotations/generateQuoteReport?Quote%20Code=${this.scanObject(this.quoteSummaryData, 'quoteCode')}`,
        {},
        { responseType: 'blob' },
      );
      const content = response.headers['content-type'];
      download(response.data, 'quote-summary.pdf', content);
      this.downloadQuote = false;
    },
    activateMailModal() {
      this.mailDialog = true;
    },
    updateDialog() {
      this.mailDialog = false;
    },
  },
  quoteSummaryData() {
    return {
      dialog: false,
    };
  },
  watch: {
    open(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (val === false) this.$emit('rfq-modal-close');
    },
  },

  computed: {
    getAge() {
      return age(this.scanObject(this.quoteSummaryData, 'client.clientDateOfBirth'));
    },
    getSummaryTitle() {
      if ([2016194, 2021197, 2021200].includes(this.scanObject(this.quoteSummaryData, 'prodCode'))) {
        return 'Policy Value';
      }
      return 'Projected Maturity Value';
    },
  },

  filters: {
    formatToHuman,
    formatNum(num) {
      return helpers.numberToString(num);
    },
    frequencyTitles(title) {
      const lib = {
        Q: 'Quarterly',
        M: 'Monthly',
        Y: 'Yearly',
        S: 'Semi Annually',
        Sp: 'Single Premium',
        F: 'Single Premium',
        A: 'Annually',
      };
      return lib[title];
    },
  },
};
</script>
