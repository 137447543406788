<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <top-card current="PaymentOptions" cover="Elimu Plan" :amount="5">Kindly provide some additional details before you
        proceed</top-card>

      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title icon="/images/icons/elimu-b.png">Choose your Mode of payment
        </card-title>
        <back-nav to="OtpConfirmation" />

        <div class="
            tw-flex tw-justify-center tw-px-4
            md:tw-px-12
            tw-mt-4
            md:tw-mt-10
          ">
          <div v-if="!paymentInitiated" class="tw-w-full md:tw-w-8/12">
            <v-tabs v-model="tab" centered icons-and-text>
              <v-tab v-if="stkSupported" href="#tab-1" class="tw-px-2" @click="currentTab = 'stk'">
                <img :src="[
                  currentTab === 'stk'
                    ? '/images/payment/mpesa-b.png'
                    : '/images/payment/mpesa.png',
                ]" width="120" alt="mpesa" />
              </v-tab>

              <v-tab v-if="paybillSupported" href="#tab-2" class="tw-px-2" @click="currentTab = 'paybil'">
                <img :src="[
                  currentTab === 'paybil'
                    ? '/images/payment/paybill-b.png'
                    : '/images/payment/paybil.png',
                ]" width="120" alt="mpesa" />
              </v-tab>

              <v-tab v-if="offlinePaymentSupported" href="#tab-3" class="tw-px-2" @click="currentTab = 'card'">
                <img :src="[
                  currentTab === 'card'
                    ? '/images/payment/card-b.png'
                    : '/images/payment/card.png',
                ]" width="120" alt="card" />
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-card class="tw-flex tw-justify-center" flat>
                  <div class="
                      tw-w-full
                      md:tw-w-8/12
                      tw-px-2
                      md:tw-px-8
                      tw-py-2
                      md:tw-py-8
                    ">
                    <p class="c-blue-text tw-text-xl my-4 tw-text-center">
                      Enter the Phone Number to pay from
                    </p>
                    <v-form v-model="stkValid">
                      <vue-tel-input-vuetify id="mobileNumber" v-bind="phoneNumberBindProps" v-model="phoneNumber"
                        class="my-2 tw-px-0 md:tw-px-6" :rules="[required('Phone Number')]" label="Phone Number." />
                      <v-card-actions class="col-sm-4 offset-sm-4 tw-mt-5 tw-pb-8">
                        <v-btn block large color="secondary" class="my-3" :loading="loading" :disabled="!stkValid"
                          @click="requestSTK">
                          Pay
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card class="tw-flex tw-justify-center" flat>
                  <div class="
                      tw-w-full
                      md:tw-w-8/12
                      tw-px-2
                      md:tw-px-8
                      tw-py-2
                      md:tw-py-8
                    ">
                    <p class="c-blue-text">From your phone;</p>
                    <ul class="tw-mt-4">
                      <li>Go to SIM Toolkit Menu/Mpesa App</li>
                      <li>Select Mpesa</li>
                      <li>Select Lipa Na Mpesa</li>
                      <li>Select Pay Bill</li>
                      <li>Enter Business No: <strong>527600</strong></li>
                      <li>
                        Enter Account No:
                        <strong>ID NUMBER OF CLIENT</strong>
                      </li>
                      <li>
                        Enter Amount: KSH.
                        <strong>e.g 76000 </strong>
                      </li>
                    </ul>
                    <v-card-actions class="col-sm-4 offset-sm-4 tw-mt-5 tw-pb-8">
                      <v-btn block large color="secondary" class="my-3" :loading="loading" :disabled="!paybillSupported"
                        @click="submitPayment">
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-3" class="tw-my-5">
                <v-form v-model="offlinePay">
                  <v-radio-group v-model="form.paymentMode" class="my-5 tw-px-0 md:tw-px-6"
                    :rules="[required('Mode of Payment')]" row>
                    <v-radio label="Direct Debit" value="directDebit" class="tw-mt-0 md:tw-mt-5 tw-pr-10"></v-radio>
                    <v-radio label="Standing Order" value="standingOrder" class="tw-mt-0 md:tw-mt-5"></v-radio>
                    <v-radio label="Salary Deduction" value="salaryDeduction"
                      class="tw-mt-5 md:tw-mt-5  md:tw-ml-10"></v-radio>
                  </v-radio-group>

                  <div v-if="form.paymentMode !== 'salaryDeduction'" class="
                    tw-grid tw-grid-cols-1
                    md:tw-grid-cols-2
                    tw-gap-4
                    md:tw-gap-8
                  ">
                    <div>
                      <label class="primary--text">Bank Name</label>
                      <v-text-field v-model="form.bankName" variant="underlined" placeholder="e.g Equity Bank"
                        :rules="[required('Bank Name')]"></v-text-field>
                    </div>
                    <div>
                      <label class="primary--text">Bank Branch</label>
                      <v-text-field v-model="form.bankBranch" variant="underlined" placeholder="e.g Westlands"
                        :rules="[required('Bank Branch')]"></v-text-field>
                    </div>
                    <div>
                      <label class="primary--text">Account Number</label>
                      <v-text-field v-model="form.accountNo" variant="underlined" type="number" placeholder="e.g 012345678"
                        :rules="[required('Account Number')]"></v-text-field>
                    </div>
                    <div>
                      <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template #activator="{ on, attrs }">
                          <label class="primary--text">Deduction Date</label>
                          <v-text-field v-model="form.deductionDate" persistent-hint readonly v-bind="attrs" v-on="on"
                            color="primary" class="md:tw-mx-5" :rules="[required('Deduction Date')]"></v-text-field>
                        </template>
                        <v-date-picker id="deductionDate" :active-picker.sync="activePicker" v-model="form.deductionDate"
                          no-title @input="menu = false" color="primary"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="mx-auto py-5 tw-w-full">
                      <h2 class="primary--text">
                        Click here to upload the {{ selectedOfflinePaymentFile.text }}
                      </h2>
                      <br>
                      <v-file-input v-model="form.offlinePaymentForm" placeholder="Upload form" color="primary"
                        label="Upload form" outlined accept=".pdf,.docx"></v-file-input>
                    </div>
                  </div>
                  <div v-else class="tw-grid tw-grid-rows-2">
                    <div class="
                    tw-grid tw-grid-cols-1
                    md:tw-grid-cols-2
                    tw-gap-4
                    md:tw-gap-8
                  ">
                      <div>
                        <label class="primary--text">Employee Name</label>
                        <v-text-field v-model="form.employeeName" variant="underlined" placeholder="e.g APA INSURANCE"
                          :rules="[required('Employee Name')]"></v-text-field>
                      </div>
                      <div>
                        <label class="primary--text">Employee / Payroll Number</label>
                        <v-text-field v-model="form.employeeNo" type="number" variant="underlined" placeholder="e.g 45869228496"
                          :rules="[required('Employee / Payroll Number')]"></v-text-field>
                      </div>
                      <div>
                        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                          max-width="290px" min-width="290px">
                          <template #activator="{ on, attrs }">
                            <label class="primary--text">Deduction Date</label>
                            <v-text-field v-model="form.deductionDate" persistent-hint readonly v-bind="attrs" v-on="on"
                              color="primary" class="md:tw-mx-5" :rules="[required('Deduction Date')]"></v-text-field>
                          </template>
                          <v-date-picker id="deductionDate" :active-picker.sync="activePicker"
                            v-model="form.deductionDate" no-title @input="menu = false" color="primary"></v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                    <div class="tw-grid tw-grid-cols-1  md:tw-grid-cols-2">
                      <div class="mx-auto py-5 tw-w-full">
                        <h2 class="primary--text">
                          Click here to upload the {{ selectedOfflinePaymentFile.text }}
                        </h2>
                        <br>
                        <v-file-input v-model="form.offlinePaymentForm" placeholder="Upload form" color="primary"
                          label="Upload form" outlined accept=".pdf,.docx"></v-file-input>
                      </div>
                    </div>
                  </div>
                  <v-card-actions class="col-sm-4 offset-sm-4 tw-mt-5 tw-pb-8">
                    <v-btn block large color="secondary" class="my-3" :loading="loading" :disabled="!offlinePay"
                      @click="submitPayment">
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
            <div class="tw-flex tw-justify-center">
              <div class="tw-w-full md:tw-w-8/12 tw-px-2 md:tw-px-8">
                <p class="c-blue-text tw-mt-4">
                  <small>Note: The transaction name that will reflect in your
                    statement is ‘APA Life’</small>
                </p>
                <p class="c-gray-text tw-mt-4">
                  For assistance: <span class="c-blue-text">0709912777</span>
                </p>
              </div>
            </div>
          </div>
          <div v-else class="tw-w-full md:tw-w-8/12 tw-flex tw-justify-center tw-flex-col">
            <div>
              <div v-if="!notificationAllowed" class="tw-w-full tw-px-4 md:tw-px-8">
                <div class="
                    tw-w-full
                    md:tw-w-full
                    tw-my-4
                    tw-flex
                    tw-items-center
                    tw-bg-blue-200
                    tw-text-center
                    lg:tw-text-left
                    tw-rounded-lg
                    lg:tw-rounded-lg
                    tw-border-blue-500 tw-px-2
                    md:tw-px-6
                    tw-py-3 tw-justify-between
                  " role="alert">
                  <p>Allow Notifications to see receive important updates</p>
                  <span class="
                      tw-bg-blue-500 tw-px-4 tw-py-0 tw-rounded-lg
                      lg:tw-rounded-lg
                      tw-text-sm tw-cursor-pointer
                    " @click="requestNotification">ALLOW</span>
                </div>
              </div>

              <div v-if="notificationDenied" class="tw-w-full tw-px-4 md:tw-px-8">
                <div class="
                    tw-w-full
                    md:tw-w-full
                    tw-my-4
                    tw-flex
                    tw-items-center
                    tw-bg-orange-200
                    tw-rounded-lg
                    lg:tw-rounded-lg
                    tw-border-orange-500 tw-px-2
                    md:tw-px-6
                    tw-py-3 tw-justify-between
                  " role="alert">
                  <p>
                    Notifications BLOCKED! Allow Notifications to receive
                    important updates, Go to site settings to allow
                  </p>
                </div>
              </div>
            </div>
            <div v-if="paymentStatus === 'PENDING'" class="tw-flex tw-justify-center">
              <img class="tw-w-32 md:tw-w-40" src="/images/hourglass.gif" alt="loading" />
            </div>
            <div class="tw-text-center">
              <h2 :class="[
                paymentStatus === 'FAILED' || paymentStatus === null
                  ? 'tw-text-red-500'
                  : 'tw-text-green-500',
                'tw-text-xl md:tw-text-3xl tw-mb-3',
              ]">
                {{
                  paymentStatus === 'PENDING'
                  ? 'Check your mobile for a payment request via MPESA'
                  : paymentStatus === 'SUCCESSFUL'
                    ? 'Payment Was Successful'
                    : 'Payment Failed, Something went wrong!'
                }}
              </h2>
              <div v-if="paymentStatus === 'PENDING'">
                <p class="c-gray-text">
                  Check your phone for MPESA payment request, <br />
                  Enter your MPESA pin to complete the process
                </p>
                <p class="c-gray-text tw-mt-2">
                  You will receive a notification on successful payment
                  regarding your policy
                </p>
                <p class="c-gray-text tw-mt-2">This may take a few minutes</p>
                <p v-if="waiting" class="tw-mt-2 tw-text-blue-500">
                  We're Almost there, Processing ....
                </p>
              </div>
            </div>
            <div v-if="!statusLoaded" class="
                tw-w-full
                lg:tw-w-3/4
                tw-self-center tw-bg-orange-200 tw-rounded-lg tw-p-4
                md:tw-mt-8
                c-gray-text
                tw-mt-4
              ">
              <p>
                In case you did not receive the payment request on your mobile
                phone, <br />
                Kindly dial *234*1*6# to allow you to receive the payment
                request when transacting. <br />
                Refresh Page to retry
              </p>
            </div>
            <div v-if="statusLoaded" class="tw-w-full lg:tw-w-3/4 tw-self-center">
              <div v-if="paymentStatus === 'SUCCESSFUL'" class="
                  tw-bg-green-200 tw-rounded-lg tw-p-4
                  md:tw-mt-8
                  c-gray-text
                  tw-mt-4 tw-mb-8
                ">
                <p>
                  <strong>Your Payment was received successfully.</strong>
                  <br />
                  Your policy is currently being processed. Thank you for
                  choosing us as your preferred insurer. APA Life, Insuring
                  Happiness
                </p>

                <div class="tw-mt-8 tw-px-4 tw-flex tw-justify-center">
                  <v-btn :to="{ name: 'Proposals' }" color="secondary">View My Proposals</v-btn>
                </div>
              </div>
              <div v-if="paymentStatus === 'FAILED'" class="
                  tw-bg-red-200 tw-rounded-lg tw-p-4
                  md:tw-mt-8
                  c-gray-text
                  tw-mt-4
                ">
                <p>
                  Your Payment was NOT successful <br />
                  You'll be required to retry paying
                </p>
              </div>
            </div>

            <div v-if="paymentRetry || paymentStatus === 'FAILED'" class="tw-my-8 tw-px-4 tw-flex tw-justify-center">
              <v-btn color="secondary" @click="paymentInitiated = false">Retry payment Request</v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular size="50" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import BackNav from '../BackNav';

import validations from '@/utils/validations';
import helpers from '@/utils/helpers';

import { formatDate, givenYearsAgo } from '@/utils/time';

export default {
  name: 'PaymentOptions',
  metaInfo: {
    title: 'Buy Cover - Payment Options',
  },
  components: {
    CardTitle,
    TopCard,
    BackNav,
  },
  data() {
    return {
      tab: null,
      pageLoading: false,
      currentTab: 'stk',
      loading: false,
      form: {
        paymentMode: 'directDebit',
      },
      htmlContent: '<v-btn icon :href="file.path" target="_blank">\n'
        + '<v-icon color="grey lighten-1">mdi-file-download</v-icon>\n'
        + '</v-btn>',
      ...validations,
      stkValid: false,
      phoneNumber: null,
      paymentInitiated: false,
      notificationAllowed: true,
      notificationDenied: false,
      paymentRetry: false,
      paybillSupported: true,
      offlinePaymentSupported: true,
      stkSupported: true,
      statusLoaded: false,
      paymentStatus: 'PENDING',
      timeoutId: null,
      mpesaRef: null,
      paybillLoading: false,
      isFilled: false,
      waiting: false,
      offlinePay: false,
      files: [
        {
          text: 'direct debit form',
          icon: 'mdi-file',
          type: 'directDebit',
          linkName: 'DIRECT-DEBIT-AUTHORITY-FORM',
        },
        {
          text: 'salary deduction form',
          icon: 'mdi-file',
          type: 'salaryDeduction',
          linkName: 'SALARY-DEDUCTION-FORM',
        },
        {
          text: 'standing order form',
          icon: 'mdi-file',
          type: 'standingOrder',
          linkName: 'STANDARD-ORDER-FORM',
        },
      ],
      menu: false,
      activePicker: null,
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'choose_payment_option_page_visited_by_agent', {
        event_category: 'Choose payment option component created',
        event_label: 'choose payment option component created',
      });
    }
  },

  mounted() {
    this.$root.$on('failed-payment', () => {
      this.paymentRetry = true;
      this.paymentStatus = 'FAILED';
      this.cancelTimeout();
    });
    this.$root.$on('successful-payment', () => {
      this.cancelTimeout();
    });
  },
  watch: {
    date() {
      this.form.coverFrom = this.formatDate(this.date);
    },
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    phoneNumber() {
      this.form.customerPhoneNumber = this.phoneNumber.replace(/\s/g, '');
    },
  },
  computed: {
    computedDateFormatted() {
      return this.form.coverFrom || this.formatDate(this.date);
    },
    ...mapState({
      clientCodeState: (state) => state.quote.clientCode,
      quoteCodeState: (state) => state.quote.quoteCode,
      clientIdState: (state) => state.quote.clientId,
    }),
    selectedOfflinePaymentFile() {
      const paymentMode = this.scanObject(this.form, 'paymentMode');
      return this.files.find((file) => file.type === paymentMode);
    },
  },
  methods: {
    proceed() {
      this.$emit('to-declaration-form');
    },
    requestSTK() {
      this.loading = true;
      this.waiting = false;
      this.paymentRetry = false;
      this.paymentStatus = 'PENDING';
      axios
        .post(
          `/lmsagent/quotations/makeQuotePaymentRequest?QuoteCode=${this.quoteCodeState}&telephoneNo=${this.form.customerPhoneNumber}`,
        )
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(
              'Request sent, check your phone to finish the process',
              'Payment',
            );
            this.paymentInitiated = true;
            // check payment status after 30 secs
            this.timeoutId = setTimeout(() => {
              this.checkPaymentStatus(response.data.data.checkoutRequestId);
            }, 30000);
          } else {
            this.$toast.error(
              'An Error occurred, please try again !',
              'Payment',
            );
          }
          this.loading = false;
        })
        .catch(() => {
          this.$toast.error('An Error occurred, please try again !', 'Payment');
          this.loading = false;
        });
    },
    requestNotification() {
      this.notificationAllowed = true;
      this.notificationDenied = false;
      this.$root.$emit('request-notification');
    },
    checkPaymentStatus(id) {
      axios
        .get(
          `/lmsagent/quotations/checkQuotePaymentStatus?QuoteCode=${this.quoteCodeState}&requestId=${id}`,
        )
        .then((resp) => {
          if (resp.data.success) {
            const status = resp.data.data.paymentStatus;
            this.paymentStatus = status;
            if (status === 'PENDING') {
              this.waiting = true;
              this.timeoutId = setTimeout(() => {
                this.checkPaymentStatus(resp.data.data.checkoutRequestId);
              }, 30000);
            }
            if (status === 'FAILED') {
              this.paymentInitiated = true;
              this.paymentRetry = true;
            }

            this.statusLoaded = true;
          }
        });
    },
    queryMpesaPaybill() {
      this.paybillLoading = true;
      axios
        .get(`/customer/payment-wallet/query-gis?mpesaRef=${this.mpesaRef}`)
        .then((resp) => {
          if (resp.data.success) {
            this.statusLoaded = true;
            this.paymentStatus = 'SUCCESSFUL';
            this.$toast.success(resp.data.msg, 'Payment');
          } else {
            this.$toast.error(resp.data.msg, 'Payment');
          }
          this.paybillLoading = false;
        });
    },
    submitPayment() {
      this.offlinePay = true;
      this.loading = true;
      axios
        .post(
          `/lmsagent/quotations/saveQuoteModeOfPayment?quoteCode=${this.quoteCodeState}&modeOfPayment=${this.form.paymentMode}`,
        )
        .then((res) => {
          if (res.status === 200) {
            this.$toast.success(
              'Payment option was submitted successfully',
            );
            this.$router.push({ name: 'Proposals' });
          }
          this.loading = false;
        })
        .catch(() => {
          this.$toast.error('Something went wrong, please try again');
          this.loading = false;
        });
    },

    formatDate,
    givenYearsAgo,
  },
  filters: {
    formatNum(num) {
      return helpers.numberToString(num);
    },
  },
};
</script>
